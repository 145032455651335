import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Loading from './Loading/Loading';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth();
  console.log('Is Authenticated:', isAuthenticated);

  if (isLoading) {
    return <Loading />; // isLoadingがtrueの場合に表示するコンポーネント
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
