import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SoftwareDownloadPage = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories/');
      setCategories(response.data);
    } catch (err) {
      setError('カテゴリの取得に失敗しました。');
      console.error('Error fetching categories:', err);
    }
  };

  const handleDownload = async (fileId) => {
    try {
      const response = await axios.get(`/api/files/${fileId}/download/`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].replace(/"/g, ''));
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      setError('ダウンロードに失敗しました。');
      console.error('Download error:', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center mb-8">ソフトウェアダウンロード</h1>
        {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}
        {categories.map(category => (
          <div key={category.id} className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">{category.name}</h2>
            <p className="mb-4">{category.description}</p>
            {category.files.map(file => (
              <div key={file.id} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h3 className="text-xl font-semibold mb-2">{file.name}</h3>
                <p className="mb-2">{file.description}</p>
                <div className="grid grid-cols-3 gap-4 mb-4">
                  <div>
                    <span className="font-semibold">バージョン:</span> {file.version}
                  </div>
                  <div>
                    <span className="font-semibold">リリース日:</span> {new Date(file.release_date).toLocaleDateString()}
                  </div>
                  <div>
                    <span className="font-semibold">ファイルサイズ:</span> {file.file_size}
                  </div>
                </div>
                <button
                  onClick={() => handleDownload(file.id)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  ダウンロード
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SoftwareDownloadPage;