import React from 'react';
import { Divider, List, Button, Drawer as MuiDrawer } from '@mui/material';
import { mainListItems, SecondaryListItems } from './listItems';
import { styled } from '@mui/system';

const drawerWidth = 240; // Drawerの幅

const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed', // 固定表示
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    borderRadius: '10px', // 角を丸くする
    overflowX: 'hidden',
    top: '80px', // AppBarの高さに合わせる
    left: '20px', // 左側の空白
    height: `calc(100vh - 80px - 30px)`, // 画面の高さからAppBarの高さと上のマージンを引いたもの
    boxShadow: theme.shadows[4], // 影を追加
    backgroundImage: 'linear-gradient(to top, #4481eb 0%, #04befe 100%)',
  },
}));

const DrawerCustom = ({ itemName, toggleItemNames, buttonLabel }) => {
  return (
    <StyledDrawer variant="permanent">
      <Divider />
      <List component="nav">
        {mainListItems}
        <Divider sx={{ my: 1 }} />
        </List>
        <Button onClick={toggleItemNames}
        sx={{
          backgroundColor: 'black',
          color: 'white',
          p: 1,
          borderRadius: '10px', // 角を丸くする
          mx: 1, // 左右のマージンを追加
          '&:hover': {
            backgroundColor: 'darkgray', // ホバー時の色を変更
          },
        }}
      > 
        {buttonLabel} 
      </Button>
        <List component="nav">
        <SecondaryListItems items={itemName} />
      </List>
      
    </StyledDrawer>
  );
};

export default DrawerCustom;
