// src/components/Loading.js
import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div>
      <div className="loading-panel first">
        <div className="icon-wrapper">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="125px" height="100%" viewBox="0 0 33.271 22.313" enableBackground="new 0 0 33.271 22.313" xmlSpace="preserve">
            <path className='cloud' d="M27.244,8.64c0.04-0.319,0.068-0.643,0.068-0.973C27.312,3.432,23.88,0,19.646,0
                c-3.069,0-6.047,2.208-7.047,5.208c-1.625-0.922-3.341-1.195-4.595-0.104c-0.012,0.01-0.019,0.022-0.031,0.033
                c-0.02,0.014-0.042,0.02-0.062,0.034c-1.236,0.875-1.686,2.49-1.336,4.123C6.543,9.292,6.51,9.292,6.51,9.292
                c-3.596,0-6.51,2.914-6.51,6.51c0,3.596,2.914,6.511,6.51,6.511h19.896c3.791,0,6.864-3.073,6.864-6.864
                C33.271,11.941,30.639,9.054,27.244,8.64z" />
          </svg>
          <div className="icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="35px" viewBox="0 0 9.652 9.207" enableBackground="new 0 0 9.652 9.207" xmlSpace="preserve">
              <path fill="#D0E2AA" d="M1.898,4.375c0.117-0.545,0.388-1.03,0.773-1.419l0.862,0.91c0.03,0.032,0.069,0.052,0.11,0.061
                c0.033,0.007,0.067,0.006,0.1-0.002c0.075-0.02,0.132-0.08,0.146-0.154l0.666-3.526C4.567,0.18,4.548,0.113,4.502,0.066
                C4.457,0.018,4.39-0.007,4.323,0.001L0.578,0.312C0.5,0.321,0.435,0.373,0.409,0.444c-0.027,0.071-0.01,0.151,0.044,0.208
                l0.958,0.981c-0.658,0.638-1.116,1.449-1.31,2.358C-0.217,5.479,0.224,7.043,1.28,8.178c0.135,0.145,0.307,0.237,0.488,0.276
                c0.279,0.06,0.582-0.008,0.808-0.208C2.949,7.917,2.519,7.641,2.32,7.269C1.87,6.422,1.7,5.302,1.898,4.375z"/>
              <path fill="#D0E2AA" d="M7.754,4.831C7.638,5.376,7.367,5.861,6.981,6.25L6.119,5.34C6.09,5.309,6.051,5.289,6.009,5.28
                c-0.033-0.007-0.067-0.007-0.1,0.002c-0.075,0.02-0.132,0.08-0.146,0.154L5.097,8.961C5.084,9.027,5.104,9.092,5.15,9.14
                c0.044,0.048,0.112,0.072,0.178,0.064l3.745-0.311c0.077-0.009,0.144-0.06,0.17-0.132c0.026-0.07,0.008-0.151-0.045-0.207
                L8.24,7.573C8.898,6.936,9.356,6.125,9.55,5.215c0.318-1.487-0.123-3.053-1.178-4.188C8.237,0.884,8.065,0.791,7.884,0.752
                C7.605,0.692,7.302,0.76,7.076,0.959c-0.373,0.33,0.059,0.605,0.257,0.979C7.783,2.784,7.953,3.904,7.754,4.831z"/>
            </svg>
          </div>
        </div>
      </div>
      <div className="loading-panel second">
        <div className="icon-wrapper">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="125px" height="100%" viewBox="0 0 33.271 22.313" enableBackground="new 0 0 33.271 22.313" xmlSpace="preserve">
            <path className='cloud' d="M27.244,8.64c0.04-0.319,0.068-0.643,0.068-0.973C27.312,3.432,23.88,0,19.646,0
                c-3.069,0-6.047,2.208-7.047,5.208c-1.625-0.922-3.341-1.195-4.595-0.104c-0.012,0.01-0.019,0.022-0.031,0.033
                c-0.02,0.014-0.042,0.02-0.062,0.034c-1.236,0.875-1.686,2.49-1.336,4.123C6.543,9.292,6.51,9.292,6.51,9.292
                c-3.596,0-6.51,2.914-6.51,6.51c0,3.596,2.914,6.511,6.51,6.511h19.896c3.791,0,6.864-3.073,6.864-6.864
                C33.271,11.941,30.639,9.054,27.244,8.64z" />
          </svg>
          <div className="icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="35px" viewBox="0 0 10.775 9.117" enableBackground="new 0 0 10.775 9.117" xmlSpace="preserve">
              <path fill="#2A7689" d="M9.133,0L8.899,0.224C8.731,0.392,8.563,0.562,8.4,0.735C7.791,1.377,7.177,2.091,6.526,2.916
                C5.96,3.634,5.38,4.419,4.805,5.249C4.653,5.466,4.507,5.68,4.358,5.898L4.329,5.9C4.298,5.943,4.255,5.974,4.198,5.974
                c-0.051,0-0.094-0.023-0.125-0.059L4.058,5.917C3.261,4.906,2.045,3.646,2.045,3.646L0,3.615C2.631,7.569,2.596,7.57,3.365,9.117
                h1.716C5.144,8.991,5.235,8.805,5.265,8.74l0.149-0.317l0.31-0.632c0.21-0.42,0.426-0.843,0.646-1.257
                c0.469-0.883,0.92-1.679,1.379-2.435C8.27,3.239,8.762,2.495,9.255,1.82c0.132-0.179,0.268-0.357,0.402-0.531l0.205-0.261
                l0.203-0.245c0.054-0.068,0.109-0.128,0.162-0.19l0.052-0.059c0.071-0.083,0.143-0.166,0.215-0.24
                C10.587,0.192,10.682,0.095,10.775,0H9.133z"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
