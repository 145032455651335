import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Paper, Typography, Box } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  maintainAspectRatio: false, // アスペクト比を維持しない　重要
  indexAxis: 'y',
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'エンジン稼働時間 /h',
    },
  },
  scales: {
    x: { ticks:{stepSize:100},
      max: 2000, // X軸の最大値を設定
    },
  },
};

const EngineChart = ({ labels, engineData, threshold }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: engineData,
        backgroundColor: ['#2DFF57', '#FF773E', '#2C7CFF'],
      }
    ],
  };

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          padding: '5px',
          zIndex: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          boxShadow: 'none',
          border: '1px solid red',
        }}
      >
        <Typography variant="subtitle2">閾値: {threshold}</Typography>
      </Paper>
      <Box sx={{ position: 'absolute',top: 10,height: '100%', width: '100%' }}>
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
};

export default EngineChart;
