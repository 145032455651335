import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaCog, FaDownload, FaHeadset, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';

const TopPageBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const menuItems = [
    { title: 'ホーム', path: '/', icon: FaHome },
    { title: '新機能テスト', path: '/mqtt', icon: FaCog },
    { title: 'ダウンロード', path: '/download', icon: FaDownload },
    { title: 'サポート', path: '/support', icon: FaHeadset },
    { title: 'お問い合わせ', path: '/contact', icon: FaEnvelope },
  ];

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="fixed z-50 top-4 left-4 p-2 bg-blue-600 text-white rounded-full shadow-lg md:hidden focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300 ease-in-out"
      >
        {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </button>

      <nav className={`fixed top-0 left-0 h-full w-64 bg-gray-900 text-white transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-all duration-300 ease-in-out z-40 overflow-y-auto`}>
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-8 text-center text-blue-400">KSK Air Cloud</h2>
          <ul>
            {menuItems.map((item, index) => {
              const Icon = item.icon;
              return (
                <li key={index} className="mb-4">
                  <Link
                    to={item.path}
                    className={`flex items-center text-lg p-3 rounded-lg transition-all duration-300 ${
                      location.pathname === item.path
                        ? 'bg-blue-600 text-white'
                        : 'text-gray-300 hover:bg-gray-800 hover:text-white'
                    }`}
                    onClick={() => setIsOpen(false)}
                  >
                    <Icon className="mr-4" size={20} />
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>

      {/* Overlay for mobile */}
      <div 
        className={`fixed inset-0 bg-black bg-opacity-50 z-30 transition-opacity duration-300 ease-in-out ${
          isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        } md:hidden`}
        onClick={() => setIsOpen(false)}
      ></div>
    </>
  );
};

export default TopPageBar;