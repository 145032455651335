import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import SignIn from './SignIn/SignIn';
import TopPage from './TopPage/TopPage';  // 新しく作成したTopPageコンポーネントをインポート
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import Download from './Download/Download';
import TMS000 from './TMS000/TMS000';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<TopPage />} />  {/* TOPPAGEをルート */}
          <Route path="/mqtt" element={<TMS000 />} />
          <Route path="/download" element={<Download />} />
          <Route path="/signin" element={<SignIn />} />  {/* SignInページのパス */}
          <Route path="/dashboard" element={<PrivateRoute element={TMS000} />} />
          {/* <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} /> */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;