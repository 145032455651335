import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { styled } from '@mui/system';

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: 'white', // アイコンの色を白に設定
  fontSize: '1.5rem', // アイコンのサイズを少し大きく設定
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: 'white', // テキストの色を白に設定
    fontFamily: 'Noto Sans JP, sans-serif', // フォントをNoto Sans Japaneseに設定
    fontSize: '1.2rem', // テキストのサイズを少し大きく設定
  },
}));

export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <CustomListItemIcon>
        <DashboardIcon style={{ fontSize: '1.8rem' }} />
      </CustomListItemIcon>
      <CustomListItemText primary="ダッシュボード" />
    </ListItemButton>

    <ListItemButton>
      <CustomListItemIcon>
        <HomeIcon style={{ fontSize: '1.8rem' }} />
      </CustomListItemIcon>
      <CustomListItemText primary="ホーム" />
    </ListItemButton>

    <ListItemButton>
      <CustomListItemIcon>
        <BarChartIcon style={{ fontSize: '1.8rem' }} />
      </CustomListItemIcon>
      <CustomListItemText primary="レポート" />
    </ListItemButton>
  </React.Fragment>
);

export const SecondaryListItems = ({ items }) => (
  <React.Fragment>
    {items.map((item, id) => (
      <ListItemButton key={id}>
        <CustomListItemIcon>
          <AssignmentIcon style={{ fontSize: '1.8rem' }} />
        </CustomListItemIcon>
        <CustomListItemText primary={item} />
      </ListItemButton>
    ))}
  </React.Fragment>
);
