import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import customIconUrl from './custom-icon.png'; // アイコン画像のパス
import customIconUrl2 from './custom-icon2.png';

// カスタムアイコンの設定（シャドウなし）
const customIcon = new L.Icon({
  iconUrl: customIconUrl,
  iconRetinaUrl: customIconUrl,
  iconSize: [80, 60], // アイコンのサイズ
  iconAnchor: [12, 41], // アイコンのアンカー位置
  popupAnchor: [25, -10], // ポップアップのアンカー位置
});

const customIcon2 = new L.Icon({
  iconUrl: customIconUrl2,
  iconRetinaUrl: customIconUrl2,
  iconSize: [30, 40], // アイコンのサイズ
  iconAnchor: [12, 41], // アイコンのアンカー位置
  popupAnchor: [25, -10], // ポップアップのアンカー位置
});

function MapView() {
  const startposition = [38.79480587582972, 140.23186351388503]
  const position = [34.43800767, 133.25643633];
  const position2 = [35.85391068584115, 139.85038484078248];
  const position3 = [-20.777392283047266, 165.12756766985638];
  const defaultZoom = 5; // 日本全体が見えるくらいのズームレベル

  return (
    <MapContainer center={startposition} zoom={defaultZoom} style={{ height: '500px', width: '100%' }}>
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <Marker position={position} icon={customIcon}>
        <Popup>
          CMMS#000
        </Popup>
      </Marker>

      <Marker position={position2} icon={customIcon2}>
        <Popup>
          CMMS#003
        </Popup>
      </Marker>

      <Marker position={position3} icon={customIcon2}>
        <Popup>
          CMMS#004
        </Popup>
      </Marker>


    </MapContainer>
  );
}

export default MapView;
