import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Slider } from '@mui/material';
import { saveAs } from 'file-saver';

const TemperatureChart = () => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [zoomDomain, setZoomDomain] = useState([0, 24]);
  const chartRef = useRef(null);

  const fetchData = useCallback(async (date) => {
    try {
      // 日本標準時での日付を取得
      const jstDateString = date.toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(/\//g, '-'); // YYYY-MM-DD形式に変換

      console.log('Selected date:', date);
      console.log('Date string for API (JST):', jstDateString);

      const apiUrl = `/api/tms000-chart/?date=${jstDateString}`;
      console.log('API request URL:', apiUrl);

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      console.log('Fetched data:', jsonData);

      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
    }
  }, []);

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate, fetchData]);

  const handleDateChange = (date) => {
    console.log('Date changed to:', date);
    setSelectedDate(date);
  };

  const handleZoomChange = (event, newValue) => {
    setZoomDomain(newValue);
  };

  const handleResetZoom = () => {
    setZoomDomain([0, 24]);
  };

  const formatXAxis = (time) => {
    return time.split(':').slice(0, 2).join(':');
  };

  const visibleData = data.filter((item) => {
    const hour = parseInt(item.time.split(':')[0], 10);
    return hour >= zoomDomain[0] && hour < zoomDomain[1];
  });

  const downloadCSV = () => {
    const csvRows = [];
    const headers = ['Date', 'Time', 'Temperature'];
    csvRows.push(headers.join(','));

    visibleData.forEach(row => {
      const values = [row.date, row.time, row.temperature];
      csvRows.push(values.join(','));
    });

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `temperature_data_${selectedDate.toISOString().split('T')[0]}.csv`);
  };

  console.log('Visible data:', visibleData);

  return (
    <div className="temperature-chart-container">
      <div className="chart-header">
        <div className="date-picker-container">
          <label htmlFor="date-picker">日付選択: </label>
          <DatePicker
            id="date-picker"
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            className="custom-datepicker"
          />
        </div>
        <button onClick={downloadCSV} className="download-btn" disabled={visibleData.length === 0}>
          CSVをダウンロード
        </button>
      </div>

      <div ref={chartRef} className="chart-container">
        {visibleData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={visibleData}
              margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                type="category"
                tickFormatter={formatXAxis}
                label={{
                  value: 'timestamp[HH:MM]',
                  position: 'insideBottom',
                  offset: -20,
                  style: { textAnchor: 'middle', fontWeight: 'bold' }
                }}
              />
              <YAxis
                domain={['dataMin - 1', 'dataMax + 1']}
                label={{
                  value: 'temperature (°C)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -15,
                  style: { textAnchor: 'middle', fontWeight: 'bold' }
                }}
              />
              <Tooltip 
                labelFormatter={(label, payload) => {
                  if (payload && payload.length > 0 && payload[0].payload) {
                    return `${payload[0].payload.date} ${label}`;
                  }
                  return label;
                }}
              />
              <Line 
                type="monotone" 
                dataKey="temperature" 
                stroke="#8884d8" 
                strokeWidth={2}
                dot={{ r: 1 }}
                activeDot={{ r: 3 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div className="no-data-message">選択された日付のデータがありません。</div>
        )}
      </div>

      <div className="zoom-controls">
        <div className="slider-container">
          <label htmlFor="zoom-slider">ズーム範囲: </label>
          <Slider
            id="zoom-slider"
            value={zoomDomain}
            onChange={handleZoomChange}
            valueLabelDisplay="auto"
            min={0}
            max={24}
            marks={[
              { value: 0, label: '0:00' },
              { value: 6, label: '6:00' },
              { value: 12, label: '12:00' },
              { value: 18, label: '18:00' },
              { value: 24, label: '24:00' }
            ]}
            step={1}
          />
        </div>
        <button onClick={handleResetZoom} className="reset-zoom-btn">
          ズームをリセット
        </button>
      </div>

      <style jsx>{`
        .temperature-chart-container {
          position: relative;
          width: 100%;
          height: 550px;
          display: flex;
          flex-direction: column;
        }
        .chart-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .date-picker-container {
          display: flex;
          align-items: center;
        }
        .custom-datepicker {
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
          margin-left: 10px;
        }
        .download-btn {
          padding: 10px 20px;
          background: #4CAF50;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .download-btn:disabled {
          background: #ccc;
          cursor: not-allowed;
        }
        .chart-container {
          width: 100%;
          height: 400px;
          position: relative;
          margin-bottom: 30px;
        }
        .no-data-message {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-size: 18px;
          color: #666;
        }
        .zoom-controls {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
        }
        .slider-container {
          flex: 1;
          margin-right: 20px;
        }
        .reset-zoom-btn {
          padding: 5px 10px;
          background: #8884d8;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default TemperatureChart;
