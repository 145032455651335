// ApexChartComponent.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import './ApexChartComponent.css';

class ApexChartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Temperature', // シリーズ名を変更
          data: [], // サンプルデータを空にして、APIから取得する
        },
      ],
      options: {
        chart: {
          type: 'area', // チャートタイプ
          stacked: false, // 積み重ね
          height: 350, // チャートサイズ
          zoom: {
            type: 'x', // ズーム方向
            enabled: true, // ズームの有無
            autoScaleYaxis: true, // Y軸の自動スケールの有無
          },
          toolbar: {
            tools: {
              download: true,   // ダウンロードアイコンの表示／非表示
              selection: true,  // 選択ツールアイコンの表示／非表示
              zoom: true,       // ズームインアイコンの表示／非表示
              zoomin: true,     // ズームインアイコンの表示／非表示
              zoomout: true,    // ズームアウトアイコンの表示／非表示
              pan: false,        // パンアイコンの表示／非表示
              reset: true       // リセットアイコン（ズームリセット）の表示／非表示
            },
            autoSelected: 'zoom' // 初期選択ツール
          }
        },
        dataLabels: {
          enabled: false, // データラベルの表示の有無
        },
        markers: {
          size: 0, // データのマーカーサイズ
        },
        title: {
          text: '全期間グラフ',
          align: 'left', // 何寄せか
        },
        fill: {
          type: 'gradient', // 塗りつぶしの種類 今回はグラデーション
          gradient: {
            shadeIntensity: 1, // 明るさの強さ
            inverseColors: false, // 色の反転
            opacityFrom: 0.7, // グラデーションの透明度
            opacityTo: 0.7,
            stops: [0, 90, 100], // グラデーションのストップ位置
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(2); // ミリオン単位の計算は不要なのでそのまま表示
            },
          },
          title: {
            text: 'temp',
          },
        },
        xaxis: {
          type: 'datetime', // 日付軸の種類
          categories: [], // サンプルデータは削除し、APIから取得する
        },
        
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.loadInitialData();
  }
  
  loadInitialData() {
    axios.get('http://49.212.183.237/api/ym920/', { params: { aggregation: 'monthly' } })
      .then(response => {
        this.updateChart(response.data);
      });
  }
  

  
  updateChart = (data) => {
    const seriesData = data.map(item => ({
      x: new Date(item.created_at).getTime(),
      y: item.temperature
    }));
    this.setState({
      series: [{ name: 'Temperature', data: seriesData }]
    });
  }
  
  
  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
          events={{
            zoomed: this.handleZoom
          }}
        />
      </div>
    );
  }
}

export default ApexChartComponent;
