import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import AppBarCustom from './AppBar/AppBarCustom';
import DrawerCustom from './SideBarCustom/DrawerCustom';
import Maintenance from './MaintenanceComp/Maintenance';
import EnginChart from './EnginChart/EnginChart';
import TotalCms from './TotalCmsComp/TotalCms';
import CustomCalendar from './calendar/CustomCalendar';
import MapView from './MapComp/MapView';
import ApexChartComponent from './chart/ApexChartComponent';

const drawerWidth = 240;

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      Yashima '
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const mdTheme = createTheme({
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

function DashboardContent() {
  const [data, setData] = useState([]);
  const [isInitial, setIsInitial] = useState(true);
  const [buttonLabel, setButtonLabel] = useState("Change Model Number");

  useEffect(() => {
    axios.get('http://49.212.183.237/api/cms/')
      .then(response => {
        const fetchedData = response.data.map(item => ({
          ...item,
          can_engine_operating_time: parseFloat(item.can_engine_operating_time),
        }));
        setData(fetchedData);
      })
      .catch(error => {
        console.error('データの取得に失敗しました', error);
      });
  }, []);

  const toggleItemNames = () => {
    setIsInitial(!isInitial);
    setButtonLabel(isInitial ? "Change CMS" : "Change Model Number");
  };

  const getNames = (isInitial, data) => {
    return data.map(item => (isInitial ? item.cms_id : item.model_number));
  };

  const currentNames = getNames(isInitial, data);
  const engineData = data.map(item => item.can_engine_operating_time);

  const threshold = 1000;
  const maintenanceItems = data.filter(item => item.can_engine_operating_time > threshold).map(item => ({
    ...item,
    displayName: isInitial ? item.cms_id : item.model_number,
  }));

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ backgroundColor: '#EEEEEE', display: 'flex', height: '100vh' }}>
        <CssBaseline />
        <AppBarCustom />

        <DrawerCustom itemName={currentNames} toggleItemNames={toggleItemNames} buttonLabel={buttonLabel} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            padding: 3,
            pt: { xs: 8, sm: 10 },
            ml: `${drawerWidth + 40}px`,
          }}
        >
                    {/* 第一のBox */}
                    <Box mb={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={5}>
                          <Grid container direction="column" spacing={3}>
                            <Grid item>
                              <Paper elevation={10}
                                sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  minHeight: 240,
                                  borderRadius: '10px',
                                  height: '34vh',
                                }}
                              >
                                <Maintenance maintenanceItems={maintenanceItems} />
                              </Paper>
                            </Grid>

                            <Grid item xs={12} md={8} lg={5}>
                              <Paper elevation={10}
                                sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '34vh',
                                  width: '100%',
                                  borderRadius: '10px',
                                }}
                              >
                                <EnginChart labels={currentNames} engineData={engineData} threshold={threshold}/>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={8} lg={7}>
                          <Paper elevation={10}
                            sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              height: 645,
                              width: '100%',
                              borderRadius: '10px',
                            }}
                          >
                            <CustomCalendar />
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
          


                    {/*第二 */}
                    <Box mb={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                          <Paper elevation={10}
                            sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              minHeight: 240,
                              borderRadius: '10px',
                              height: '34vh',
                            }}
                          >
                            <TotalCms labels={currentNames} />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                          <Paper elevation={10}
                            sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              minHeight: 240,
                              borderRadius: '10px',
                              height: '34vh',
                            }}
                          >
                            <TotalCms labels={currentNames} />
                          </Paper>
                        </Grid>

                        <Grid item xs={12} md={4} lg={6}>
                          <Paper elevation={10}
                            sx={{
                              p: 2,
                              borderRadius: '10px',
                              display: 'flex',
                              height: 500,
                              flexDirection: 'column',
                            }}
                          >
                            <MapView />
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
              


       

          <Box mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={7}>
                <Paper elevation={10}
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 430,
                    width: '100%',
                    borderRadius: '10px',
                  }}
                >
                  <ApexChartComponent />
                </Paper>
              </Grid>

              <Grid item xs={12} md={4} lg={5}>
                <Paper elevation={10}
                  sx={{
                    p: 2,
                    borderRadius: '10px',
                    display: 'flex',
                    height: 430,
                    flexDirection: 'column',
                  }}
                >
                  <MapView />
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Copyright sx={{ pt: 4 }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
