import React from 'react';
import { useNavigate } from 'react-router-dom';
import TopPageBar from './TopPageBar';

const WaveAnimation = ({ height }) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = height;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const drawWave = (t) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();

      const waveColors = ['rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0.2)', 'rgba(255, 255, 255, 0.1)'];

      waveColors.forEach((color, index) => {
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.moveTo(0, canvas.height / 2);

        for (let i = 0; i < canvas.width; i++) {
          ctx.lineTo(
            i,
            canvas.height / 2 +
              Math.sin(i * 0.02 + t + index * 2) * 20 +
              Math.sin(i * 0.01 + t + index * 2) * 15
          );
        }

        ctx.stroke();
      });

      animationFrameId = requestAnimationFrame(() => drawWave(t + 0.05));
    };

    drawWave(0);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, [height]);

  return <canvas ref={canvasRef} style={{ width: '100%', height: `${height}px`, position: 'absolute', top: 0, left: 0, zIndex: 0 }} />;
};

const TopPage = () => {
  const navigate = useNavigate();

  const menuItems = [
    { id: 1, title: '調和工業様', path: '/signin', icon: '📊' },
    { id: 2, title: 'ソフトウェアDL', path: '/Download', icon: '💾' },
    { id: 3, title: '計測ソフト本社', path: 'https://keisoku-soft.co.jp/', icon: '🏢', external: true },
    { id: 4, title: 'お問い合わせ', path: '/contact', icon: '✉️' },
    { id: 5, title: 'サポート', path: '/support', icon: '🛠️' },
    { id: 6, title: 'ログイン', path: '/login', icon: '🔐' },
  ];

  const handleNavigation = (item) => {
    if (item.external) {
      window.open(item.path, '_blank', 'noopener,noreferrer');
    } else {
      navigate(item.path);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-600 via-blue-500 to-blue-400 text-white relative">
      <TopPageBar />
      <WaveAnimation height={300} />
      <div className="md:ml-72 transition-all duration-300 relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-8">
          {/* Header */}
          <header className="text-center mb-16 pt-16">
            <h1 className="text-5xl font-bold text-white mb-4">KSK Air Cloud</h1>
          </header>

          {/* Main Content */}
          <main>
            {/* Menu Items */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
              {menuItems.map((item) => (
                <div
                  key={item.id}
                  className="bg-white bg-opacity-20 rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-105 cursor-pointer text-center"
                  onClick={() => handleNavigation(item)}
                >
                  <div className="text-4xl mb-4">{item.icon}</div>
                  <h2 className="text-xl font-semibold">{item.title}</h2>
                </div>
              ))}
            </div>
          </main>
        </div>

        {/* Footer */}
        <footer className="bg-blue-800 text-white py-12 mt-16">
          <div className="max-w-6xl mx-auto px-4">
            <div className="flex flex-wrap justify-between">
              <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <h3 className="text-3xl font-bold mb-4">KSK Air Cloud</h3>
                <p className="text-lg">挿入予定</p>
              </div>
              <div className="w-full md:w-1/2">
                <h4 className="text-2xl font-semibold mb-4">お問い合わせ</h4>
                <p className="text-lg mb-4">お問い合わせ処理はまだ作ってない</p>
                <button 
                  onClick={() => navigate('/contact')} 
                  className="bg-white text-blue-800 font-bold py-3 px-6 rounded-lg text-lg hover:bg-blue-100 transition duration-300"
                >
                  お問い合わせ
                </button>
              </div>
            </div>
            <div className="mt-12 text-center">
              <p className="text-lg">&copy; 2024 make by Yashima.</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default TopPage;