import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

const isTokenValid = (token) => {
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    const now = Date.now() / 1000;
    return decoded.exp > now;
  } catch (error) {
    console.error('Invalid token:', error);
    return false;
  }
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    accessToken: null,
    isLoading: true
  });

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    console.log('Token from storage:', token);
    if (token && isTokenValid(token)) {
      console.log('Setting authenticated state to true');
      setAuth({
        isAuthenticated: true,
        accessToken: token,
        isLoading: false
      });
    } else {
      console.log('No valid token found, setting authenticated state to false');
      localStorage.removeItem('access_token');
      setAuth({
        isAuthenticated: false,
        accessToken: null,
        isLoading: false
      });
    }
  }, []);

  const login = (accessToken, refreshToken) => {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    console.log('Login successful, setting authenticated state to true');
    setAuth({
      isAuthenticated: true,
      accessToken: accessToken,
      isLoading: false
    });
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    console.log('Logout, setting authenticated state to false');
    setAuth({
      isAuthenticated: false,
      accessToken: null,
      isLoading: false
    });
  };

  return (
    <AuthContext.Provider value={{ ...auth, login, logout }}>
      {!auth.isLoading ? children : <div>Loading...</div>}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
